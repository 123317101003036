import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import moment from 'moment';
import {Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, Select, Alert} from 'antd';
import {useHistory} from 'react-router-dom';
import {LeftOutlined} from "@ant-design/icons";
import {CityPicker, FileInputSingle, GroupPicker} from '../components';
import api from '../service/index'
import {Link} from 'react-router-dom';
import {useCallback} from "react";
import {env} from '../app.config'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const {Option} = Select;

const AttendeeDetail = (props) => {
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
    let settings = useGlobal("settings")[0]
    let profileSettings;
    let [languagesCms] = useGlobal("languagesCms"); 
    let [langCms] = useGlobal("langCms"); 
    let [langCode] = useGlobal("langCode"); 
    if (settings) {
        settings.forEach(element => {
            if (element._id === "profileSettings") {
                profileSettings = element
            }
        });
    }

    let newRecord = {
        name: '',
        lastname: '',
        role: 'user',
        groups: [],
        phone: '',
        email: '',
        company: '',
        department: '',
        position: '',
        jobPhone: '',
        presidential: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
        skype: '',
        registerNo: '',
        jobLocation: '',
        tc: '',
        birthdayString: '',
        lang: langCode ? langCode : langCms? langCms[0].code : "",
        isSupportAdmin: '',
        showMention: ''
    }
    let loginType = env.LOGINTYPE
    let [data, setData] = useState(id ? [] : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)
    let [isSmall,] = useGlobal('isSmall')

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let passMessage = ""

    if (loginType === "phone")
    passMessage = languagesCms.PASSMESSAGE
     
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
            api.get("/rest/attendees/" + id).then(({data: {result, result_message}}) => {
                if (id) {
                    result.sendSms = false
                }
                delete result.password;
                setData(result);
                setLoading(false)
            });
        }
    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        if (data.name === null || data.name.length === 0)
            errors.name = languagesCms.ENFORCED

        if (data.lastname === null || data.lastname.length === 0)
            errors.lastname = languagesCms.ENFORCED

        if (loginType === "phone") {
            if (data.phone === null || data.phone.length === 0)
                errors.phone = languagesCms.ENFORCED
        } else if (loginType === "emailPass") {
            if (data.email === null || data.email.length === 0)
                errors.email = languagesCms.ENFORCED
        } else {
            if (data.email === null || data.email.length === 0)
                errors.email = languagesCms.ENFORCED
            if (data.phone === null || data.phone.length === 0)
                errors.phone = languagesCms.ENFORCED
        }
        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);


    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({top: 20, behavior: 'smooth'});
        } else {

            if (id) {
                if (data.phone && !data.phone.startsWith("+")) {
                    data.phone = "+" + data.phone
                }
                api.put("/rest/attendees/" + id, data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/attendees')
                    } else
                        message.error(result_message.message, 2);
                })
            } else {
                if (data.phone && !data.phone.startsWith("+")) {
                    data.phone = "+" + data.phone
                }
                api.post("/rest/attendees", data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        history.push('/attendees')
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    };
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/attendees">
                        <Button type="light" icon={<LeftOutlined/>} size={'large'}>{!isSmall && languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="Avatar" help={errors.name}
                                           validateStatus={errors.name ? 'error' : 'success'}>
                                    <FileInputSingle languagesCms={languagesCms} record={data} setRecord={setData} name="avatar"/>
                                    <Alert message="Avatar 1024 x 1024 çözünürlüğünde olmalıdır." banner/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="Rol" required help={errors.role}
                                           validateStatus={errors.role ? 'error' : 'success'}>
                                    <Select defaultValue={(data.role) ? data.role : 'user'} style={{width: 120}}
                                            onChange={v => {
                                                setData({...data, role: v})
                                            }}>
                                        <Option value="user">User</Option>
                                        < Option value="admin">Admin</Option>
                                    </Select>
                                    <br/><small> {languagesCms.ADMIN_INFO_MESSAGE}</small>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.GROUP} help={errors.groups}
                                           validateStatus={errors.active ? 'error' : 'success'}>
                                    <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.EMAIL} required={loginType === "emailPass" ? true : false}
                                           help={errors.email} validateStatus={errors.email ? 'error' : 'success'}>
                                    <Input name="email" value={data.email}
                                           onChange={e => setData({...data, email: e.target.value})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.PHONE} required={loginType === "phone" ? true : false}
                                           help={errors.phone} validateStatus={errors.phone ? 'error' : 'success'}>
                                               <PhoneInput country={'tr'} value={data.phone} onChange={e => setData({...data, phone: e})} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.NAME} required help={errors.name}
                                           validateStatus={errors.name ? 'error' : 'success'}>
                                    <Input name="name" value={data.name}
                                           onChange={e => setData({...data, name: e.target.value})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.LASTNAME} required help={errors.lastname}
                                           validateStatus={errors.lastname ? 'error' : 'success'}>
                                    <Input name="lastname" value={data.lastname}
                                           onChange={e => setData({...data, lastname: e.target.value})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.CREATE_NEW_PASSWORD} help={errors.password}
                                           validateStatus={errors.password ? 'error' : 'success'}>
                                    <Input name="password"
                                           onChange={e => setData({...data, password: e.target.value})}/>
                                    <br/><small>{languagesCms.PASSWORD_LEAVE_BLANK}</small>
                                    <br/><small>{passMessage}</small>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.SEND_SMS} help={errors.sendSms}
                                           validateStatus={errors.sendSms ? 'error' : 'success'}>
                                    <Switch checked={data.sendSms ? true : false} checkedChildren={languagesCms.SEND}
                                            unCheckedChildren={languagesCms.DONTSEND}
                                            onChange={v => setData({...data, sendSms: v})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            {profileSettings.showCompanyDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.COMPANY} help={errors.company}
                                               validateStatus={errors.company ? 'error' : 'success'}>
                                        <Input name="company" value={data.company}
                                               onChange={e => setData({...data, company: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showDepartmentDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.DEPARTMENT} help={errors.department}
                                               validateStatus={errors.department ? 'error' : 'success'}>
                                        <Input name="department" value={data.department}
                                               onChange={e => setData({...data, department: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row direction="row">
                            {profileSettings.showPositionDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.POSITION} help={errors.position}
                                               validateStatus={errors.position ? 'error' : 'success'}>
                                        <Input name="position" value={data.position}
                                               onChange={e => setData({...data, position: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showJobPhoneDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.JOB_PHONE} help={errors.jobPhone}
                                               validateStatus={errors.jobPhone ? 'error' : 'success'}>
                                        <Input name="jobPhone" value={data.jobPhone}
                                               onChange={e => setData({...data, jobPhone: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.PRESIDENTIAL} help={errors.presidential}
                                           validateStatus={errors.presidential ? 'error' : 'success'}>
                                    <Input name="presidential" value={data.presidential}
                                           onChange={e => setData({...data, presidential: e.target.value})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.JOB_LOCATION} help={errors.jobLocation}
                                           validateStatus={errors.jobLocation ? 'error' : 'success'}>
                                    <Input name="jobLocation" value={data.jobLocation}
                                           onChange={e => setData({...data, jobLocation: e.target.value})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            {profileSettings.showJobStartDateDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.JOB_START_DATE} help={errors.jobStartDate}
                                               validateStatus={errors.jobStartDate ? 'error' : 'success'}>
                                        <DatePicker defaultValue={() => moment(data.jobStartDate)}
                                                    onChange={v => setData({...data, jobStartDate: v})}
                                                    format='DD/MM/YYYY' showTime={true}/>
                                        <br/><small>{languagesCms.JOB_START_DATE_MESSAGE} </small>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showLocationDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.CITY} help={errors.location}
                                               validateStatus={errors.location ? 'error' : 'success'}>
                                        <CityPicker languagesCms={languagesCms} record={data} setRecord={setData} name="location" mode="single"/>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>

                        <Row direction="row">
                            {profileSettings.showBirthdayDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.BIRTHDAY} help={errors.birthday}
                                               validateStatus={errors.birthday ? 'error' : 'success'}>
                                        <DatePicker defaultValue={() => moment(data.birthday)}
                                                    onChange={v => setData({...data, birthday: v})} format='DD-MM'
                                                    showTime={true}/>
                                        <br/><small>{languagesCms.BIRTHDAY_MESSAGE}</small>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showBloodDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.BLOOD_TYPE} help={errors.blood}
                                               validateStatus={errors.blood ? 'error' : 'success'}>
                                        <Select defaultValue={(data.blood) ? data.blood : languagesCms.SELECT_BLOOD_TYPE}
                                                style={{width: 250}} onChange={v => {
                                            setData({...data, blood: v})
                                        }}>
                                            <Select.Option value="A Rh+">A Rh+</Select.Option>
                                            <Select.Option value="A Rh-">A Rh-</Select.Option>
                                            <Select.Option value="B Rh+">B Rh+</Select.Option>
                                            <Select.Option value="B Rh-">B Rh-</Select.Option>
                                            <Select.Option value="0 Rh+">0 Rh+</Select.Option>
                                            <Select.Option value="0 Rh-">0 Rh-</Select.Option>
                                            <Select.Option value="AB Rh+">AB Rh+</Select.Option>
                                            <Select.Option value="AB Rh-">AB Rh-</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>

                        <Row direction="row">
                            {profileSettings.showTcDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Tc" help={errors.tc}
                                               validateStatus={errors.tc ? 'error' : 'success'}>
                                        <Input name="tc" value={data.tc}
                                               onChange={e => setData({...data, tc: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showBirthdayStringDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.BIRTHDAY_STRING} help={errors.birthdayString}
                                               validateStatus={errors.birthdayString ? 'error' : 'success'}>
                                        <Input name="birthdayString" value={data.birthdayString}
                                               onChange={e => setData({...data, birthdayString: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>

                        <Row direction="row">
                            {profileSettings.showFacebookDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Facebook:" help={errors.facebook}
                                               validateStatus={errors.facebook ? 'error' : 'success'}>
                                        <Input name="facebook" value={data.facebook}
                                               onChange={e => setData({...data, facebook: e.target.value})}/>
                                        <br/><small>{languagesCms.ONLY_USERNAME}</small>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showTwitterDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Twitter:" help={errors.twitter}
                                               validateStatus={errors.twitter ? 'error' : 'success'}>
                                        <Input name="twitter" value={data.twitter}
                                               onChange={e => setData({...data, twitter: e.target.value})}/>
                                        <br/><small>{languagesCms.ONLY_USERNAME}</small>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showInstagramDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Instagram:" help={errors.instagram}
                                               validateStatus={errors.instagram ? 'error' : 'success'}>
                                        <Input name="instagram" value={data.instagram}
                                               onChange={e => setData({...data, instagram: e.target.value})}/>
                                        <br/><small>{languagesCms.ONLY_USERNAME}</small>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showLinkedinDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Linkedin:" help={errors.linkedin}
                                               validateStatus={errors.linkedin ? 'error' : 'success'}>
                                        <Input name="linkedin" value={data.linkedin}
                                               onChange={e => setData({...data, linkedin: e.target.value})}/>
                                        <br/><small>{languagesCms.ONLY_USERNAME}</small>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showSkypeDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Skype:" help={errors.skype}
                                               validateStatus={errors.skype ? 'error' : 'success'}>
                                        <Input name="Skype" value={data.skype}
                                               onChange={e => setData({...data, skype: e.target.value})}/>
                                        <br/><small>{languagesCms.ONLY_USERNAME}</small>
                                    </Form.Item>
                                </Col>
                            }
                            {profileSettings.showRegisterNoDev === true &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.REGISTRATION_NUMBER} help={errors.registerNo}
                                               validateStatus={errors.registerNo ? 'error' : 'success'}>
                                        <Input name="Sicil No" value={data.registerNo}
                                               onChange={e => setData({...data, registerNo: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.DATA_SHOW_JOBSTART} help={errors.show_jobStart}
                                           validateStatus={errors.show_jobStart ? 'error' : 'success'}>
                                    <Switch checked={data.show_jobStart ? true : false} checkedChildren={languagesCms.SHARE}
                                            unCheckedChildren={languagesCms.DONTSHARE}
                                            onChange={v => setData({...data, show_jobStart: v})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.DATA_SHOW_BIRTHDAY} help={errors.show_birthday}
                                           validateStatus={errors.show_birthday ? 'error' : 'success'}>
                                    <Switch checked={data.show_birthday ? true : false} checkedChildren={languagesCms.SHARE}
                                            unCheckedChildren={languagesCms.DONTSHARE}
                                            onChange={v => setData({...data, show_birthday: v})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.DATA_SHOW_BLOOD} help={errors.show_blood}
                                           validateStatus={errors.show_blood ? 'error' : 'success'}>
                                    <Switch checked={data.show_blood ? true : false} checkedChildren={languagesCms.SHARE}
                                            unCheckedChildren={languagesCms.DONTSHARE}
                                            onChange={v => setData({...data, show_blood: v})}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.IS_SUPPORT_ADMIN} help={errors.isSupportAdmin}
                                           validateStatus={errors.isSupportAdmin ? 'error' : 'success'}>
                                    <Switch checked={data.isSupportAdmin ? true : false} checkedChildren={languagesCms.YES}
                                            unCheckedChildren={languagesCms.NO}
                                            onChange={v => setData({...data, isSupportAdmin: v})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large"
                                            block> {languagesCms.SAVE} </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </div>
    );
};
export default AttendeeDetail;
