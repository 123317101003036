import React, { useEffect, useState, useMemo, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, InputNumber, Select,Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { MapInput,FileInput, GroupPicker, SendNotificationInput, LangPicker } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";

const { Option } = Select;

const TaskCommentDetail = (props) => {
  const quillRef = useRef(null);

  let params = useParams()
  let history = useHistory()
  let taskCommentId = params.taskCommentId !== "add" ? params.taskCommentId : false;
  let taskId = params.taskId !== "add" ? params.taskId : false;

  let [languagesCms] = useGlobal("languagesCms"); 

  let newRecord = {
    comment: '',
    medias:[],
  }

  let [data, setData] = useState(taskCommentId ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(taskCommentId ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)
  
  let [modules] = useGlobal("modules");
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }
  console.log("taskCommentId      ",taskCommentId)

   
  useEffect(() => {
    if (taskCommentId) {
      setLoading(true)
        api.get("/rest/taskComments/" + taskCommentId).then(({ data: { result, result_message } }) => {
            console.log("result taskComments       ",result)
          setData(result);
          setLoading(false)
        });
      }
  }, [taskCommentId]);
  console.log("result taskComments       ",data)

  let validate = useCallback(() => {
    let errors = {};


    if (data.comment === null || data.comment.length === 0)
      errors.comment = languagesCms.ENFORCED

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (taskId) {
        delete data.attendee_id
        api.put("/rest/taskComments/" + taskId, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/taskComments/'+taskId)
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {

        api.post("/rest/taskComments", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/taskComments/'+taskId)
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };

 let imageHandler = () => {
  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
      const quill = quillRef.current.getEditor();
      const file = input.files[0];
      const formData = new FormData();

      formData.append('files_0', file);

      const range = quill.getSelection(true);

      quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
      quill.setSelection(range.index + 1);
   
      let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data'} }).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          return result[0].url;
        }
      });
      quill.deleteText(range.index, 1);
      quill.insertEmbed(range.index, 'image', url);
    };
  }

  const formats = ['header','font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link','image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code','script','clean']
    const quillmodules = useMemo(() => ({
        toolbar: {
            container: [
                [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ['link', 'image', 'video'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { align: [] },
                    { direction: 'rtl' },
                  ],
                ['clean'],
                ['code-block'],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{ 'color': [] }, { 'background': [] }],
                [{ script: 'sub' }, { script: 'super' }, 'formula']
            ],
            handlers: {
                image: imageHandler
            }
        }
    }), [])

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/taskComments/"+taskId}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">

        <Card title={taskCommentId ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>
    {data && data.attendee_id &&
            <Row direction="row">

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label={"Kullanıcı"}>
                <p>{data.attendee_id.name+" "+data.attendee_id.lastname}</p> 
              </Form.Item>
            </Col>
          </Row>
    }



            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={"Yorum"} required help={errors.comment} validateStatus={errors.comment ? 'error' : 'success'}>
                  <Input name="comment" value={data.comment} onChange={e => setData({ ...data, comment: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
 
            <Row direction="row">
              <Col span={12}>
                <Form.Item label="Medyalar">
                <FileInput languagesCms={languagesCms} name='medias' title='Medyalar' type='file' multi={false} ext={['image', 'video']} record={data} setRecord={setData} />
                </Form.Item>
                </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Card>
      </div>
    </div>
  );
};



export default TaskCommentDetail;
