import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Form, Input, Button, Popconfirm } from 'antd';

export default (props) => {
  const { record, setRecord } = props;
  let markers = record ? record : [];
  let poly = null;
  let [mapCenter] = useState({
    lat: 41.049531052374945,
    lng: 29.079319894358814,
  });
  let languagesCms = props.languagesCms;

  const [map, setMap] = useState(null);
  const [google, setGoogle] = useState(null);
  const mapContainerRef = useRef(null);

  // Google Maps script yükleniyor
  const loadGoogleMaps = () => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC1NyStYSSVdxiUaiT26GNYb3jkN-W-Uq4&libraries=places`;
    script.async = true;
    script.onload = () => {
      setGoogle(window.google); // Google API yüklendikten sonra google nesnesini set ediyoruz
    };
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadGoogleMaps(); // Google Maps script'ini yükle
  }, []);

  useEffect(() => {
    if (google && mapContainerRef.current) {
      // Google API yüklendikten sonra harita nesnesini başlatıyoruz
      const mapInstance = new google.maps.Map(mapContainerRef.current, {
        center: mapCenter,
        zoom: 10,
      });

      setMap(mapInstance);

      // Polyline eklemek için bir polyline nesnesi oluşturuyoruz
      poly = new google.maps.Polyline({
        strokeColor: 'green',
        strokeOpacity: 1.0,
        strokeWeight: 10,
        editable: true,
      });
      const bounds = new google.maps.LatLngBounds();
console.log("sayfa ilk yükleme record     ",record)
      if (record) {
        // Kaydedilen koordinatlar varsa, önceki pinleri ve polyline'ları ekliyoruz
        record.forEach((item) => {
          const myLatlng = new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lon));
          if (item.type === 'line') {
            addPoly(myLatlng);
          } else if (item.type === 'pin') {
            addPin(myLatlng, item.name, item.content);
          }
          bounds.extend(myLatlng);
        });
        mapInstance.fitBounds(bounds);
      }

      // Harita tıklama olayını dinliyoruz ve yeni pin ekliyoruz
      google.maps.event.addListener(mapInstance, 'click', (event) => {
        addPoly(event.latLng);
        addPin(event.latLng);
      });

      poly.setMap(mapInstance);

      // SearchBox'ı başlatıyoruz
      const input = document.createElement('input');
      input.type = 'text';
      input.placeholder = 'Search for places';
      input.style.width = '100%';
      input.style.padding = '10px';
      input.style.marginBottom = '20px';

      const searchBoxInstance = new google.maps.places.SearchBox(input);

      mapInstance.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      google.maps.event.addListener(searchBoxInstance, 'places_changed', function () {
        const places = searchBoxInstance.getPlaces();
        if (places.length === 0) return;
        const place = places[0]; // İlk yeri alıyoruz

        const location = place.geometry.location;
        addPin(location, place.name, place.formatted_address);

        mapInstance.setCenter(location);
        mapInstance.setZoom(14);
      });
    }
  }, [google]); // Google API yüklendikçe bu effect çalışacak
  
  const normalizePin = (pin) => {
    return {
      lat: Number(pin.lat),
      lng: pin.lng ? Number(pin.lng) : Number(pin.lon),
      name: pin.name || '',
      content: pin.content || '',
      type: pin.type || 'pin',
    };
  };
  // Pin ekleme fonksiyonu
  const addPin = (location, name = '', content = '') => {
    const newPin = normalizePin({
      lat: location.lat(),
      lng: location.lng(),
      name,
      content,
    });
  
    // Eski ve yeni model farkını normalize ederek kontrol et
    if (record) {

      const isDuplicate = record.some((pin) => {
        console.log("pinnnnn   ",pin)
        return (
          pin.type == 'pin' && // Sadece pin tipindekileri kontrol et
          Number(pin.lat) === newPin.lat &&
          Number(pin.lng || pin.lon) === newPin.lng
        );
      });
    
      if (isDuplicate) {
        console.log('Aynı pin zaten var.');
        return; // Eğer zaten var ise pin eklemiyoruz
      }
      const isDuplicateLine = record.some((pin) => {
        console.log("line   ",pin)
        return (
          Number(pin.lat) === newPin.lat &&
          Number(pin.lng || pin.lon) === newPin.lng
        );
      });
    
      if (isDuplicateLine) {
        console.log('Aynı line zaten var.');
        return; // Eğer zaten var ise pin eklemiyoruz
      }
  }

    const square = {
      path: google.maps.SymbolPath.CIRCLE,
      strokeColor: 'orange',
      fillColor: 'orange',
      fillOpacity: 1,
      scale: 10,
      zIndex: 9999,
    };

    const marker = new google.maps.Marker({
      position: location,
      map: map,
      icon: square,
      draggable: true,
      label: {
        text: (markers.length + 1).toString(),
        fontWeight: 'bold',
      },
      zIndex: 9999,
    });

    const markerPoint = {
      lat: location.lat(),
      lng: location.lng(),
      name: name,
      content: content,
    };
    const updatedMarkers = [...markers, markerPoint];
    console.log("markers    ",updatedMarkers)

    setRecord([markerPoint]);
    markers.push(markerPoint);

    google.maps.event.addListener(updatedMarkers, 'dragend', function (event) {
      markerPoint.lat = event.latLng.lat();
      markerPoint.lng = event.latLng.lng();
      hesapla();
    });

    hesapla();
  };
console.log("records     ",record)
  // Polyline ekleme fonksiyonu
  const addPoly = (location) => {
    if (!poly) return; // Polyline henüz yüklenmediyse işlem yapma

    const path = poly.getPath();
    path.push(location);

    google.maps.event.addListener(path, 'dragend', hesapla);
    google.maps.event.addListener(path, 'insert_at', hesapla);
    google.maps.event.addListener(path, 'remove_at', hesapla);
    google.maps.event.addListener(path, 'set_at', hesapla);
  };

  // Harita üzerinde polyline ve pin ekleme hesaplama fonksiyonu
  const hesapla = () => {
    if (!poly) return; // Eğer polyline nesnesi oluşturulmamışsa işlem yapma

    // Polyline path'ini temizliyoruz
    const path = poly.getPath();
  
    let points = [];
    console.log("path     ",path)
    for (let i = 0; i < path.length; i++) {
      console.log("iiiiii    ",i)

      const point = {
        type: 'line',
        name: '',
        content: '',
        lat: path.getAt(i).lat().toString(),
        lon: path.getAt(i).lng().toString(),
      };
      points.push(point);
    }
    console.log("markers hesapla   ",markers)
    for (let j = 0; j < markers.length; j++) {
      console.log("jjjjjj    ",j)
      if(j &&( markers[j].type=='pin' || !markers[j].type)){
      const pointer = {
        type: 'pin',
        name: markers[j].name,
        content: markers[j].content,
        lat: markers[j].lat.toString(),
        lon: markers[j].lon ? markers[j].lon : markers[j].lng.toString(),
      };
      points.push(pointer);

    }
    }

    setRecord(points);
  };
  let removeMarker = (key) => {
    let newRecord = [...record];  // Copy the record to avoid mutating state directly
    const markerToRemove = newRecord[key];
  
    // Marker'ı haritadan kaldırıyoruz
    if (markerToRemove) {
      // Marker'ın haritadan kaldırılması
      const markerIndex = markers.findIndex(
        (marker) => marker.lat === markerToRemove.lat && marker.lng === markerToRemove.lng
      );
  
      if (markerIndex !== -1) {
        // Haritadan marker'ı kaldır
        markers.splice(markerIndex, 1); // 'markers' array'inden kaldır
      }
    }
  
    // Yeni 'record' verisini state'e set ediyoruz
    newRecord.splice(key, 1);  // 'record' array'inden marker'ı kaldır
    setRecord(newRecord);

    const mapInstance = new google.maps.Map(mapContainerRef.current, {
      center: mapCenter,
      zoom: 10,
    });

    setMap(mapInstance);

    // Polyline eklemek için bir polyline nesnesi oluşturuyoruz
    poly = new google.maps.Polyline({
      strokeColor: 'green',
      strokeOpacity: 1.0,
      strokeWeight: 10,
      editable: true,
    });
    const bounds = new google.maps.LatLngBounds();

    if (record) {
      // Kaydedilen koordinatlar varsa, önceki pinleri ve polyline'ları ekliyoruz
      record.forEach((item) => {
        const myLatlng = new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lon));
        if (item.type === 'line') {
          addPoly(myLatlng);
        } else if (item.type === 'pin') {
          addPin(myLatlng, item.name, item.content);
        }
        bounds.extend(myLatlng);
      });
      mapInstance.fitBounds(bounds);
    }

    // Harita tıklama olayını dinliyoruz ve yeni pin ekliyoruz
    google.maps.event.addListener(mapInstance, 'click', (event) => {
      addPoly(event.latLng);
      addPin(event.latLng);
    });

    poly.setMap(mapInstance);
    };
  
  
console.log("record     ",record)
  return (
    <div className="mapWrap" style={{ paddingBottom: 40 }}>
      <div
        ref={mapContainerRef}
        style={{
          width: '100%',
          height: 300,
          marginBottom: 30,
          border: '1px solid #d9d9d9',
        }}
      />
      <div
        style={{
          padding: 30,
          background: '#F9FBFE',
          marginBottom: 30,
          position: 'relative',
        }}
      >
        {record &&
          record.map((item, key) => {
            if (item.type === 'pin') {
              return (
                <Row key={key} direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <Form.Item label={languagesCms.STATION + (key + 1)}>
                      <Input
                        name="name"
                        value={item.name}
                        onChange={(e) => {
                          let newRecord = [...record];
                          newRecord[key].name = e.target.value;
                          setRecord(newRecord);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item>
                      <Input
                        name="content"
                        value={item.content}
                        onChange={(e) => {
                          let newRecord = [...record];
                          newRecord[key].content = e.target.value;
                          setRecord(newRecord);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 2 }}>
                    <Popconfirm
                      onConfirm={() => removeMarker(key)}
                      title={languagesCms.YOUR_ROUTE_ON_THE_MAP_WILL_ALSO_BE_DELETED}
                      okText={languagesCms.OK_TEXT}
                      cancelText={languagesCms.CANCEL_TEXT}
                    >
                      <Button type="warning" htmlType="button" size="large">
                        {languagesCms.DELETE}
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              );
            } else return true;
          })}
      </div>
    </div>
  );
};

