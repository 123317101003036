import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm} from 'antd';
import moment from 'moment';
import api from '../service/index'
import {Link,useParams,useHistory} from 'react-router-dom';
import qs from 'qs';

import {
    EditOutlined,
    DeleteOutlined,
    LeftOutlined,
    CheckOutlined,
    FileExcelOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const TaskComments = (props) => {
  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [modules] = useGlobal("modules");
    let [isSmall] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = '-createdAt';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,task_id:id};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "comment";
        }
        let restData = await api.get(`/rest/taskComments?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }
console.log("data   ",data)
    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let getReport = async () => {
        let result = await api.get(`/rest/reports/taskComments?id=${id}`)
        const file = new Blob(["\ufeff", result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `${"taskComments".replace(/ /g, '')}_report.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
    let deleteRow = async (item_id) => {
        api.delete(`/rest/taskComments/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/taskComments/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }
    let columns = [
        {
      title: "Yorum",
            dataIndex: 'comment',
            key: 'comment',
            sorter: (a, b) => a.comment - b.comment,
            sortDirections: ['descend', 'ascend']
        },  
    {
        title: "Oluşturulma Tarihi",
        dataIndex: 'createdAt',
        key: 'createdAt',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.createdAt - b.createdAt,
        render: (text) => moment(text).format('DD-MM-YYYY HH:mm'),
      },
      {
        title: "Güncellenme Tarihi",
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.updatedAt - b.updatedAt,
        render: (text) => moment(text).format('DD-MM-YYYY HH:mm'),
      },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/taskComments/"+id+"/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {isSmall ? '' : languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                <Button type="primary" style={{marginRight: "5px"}} size="large" icon={<FileExcelOutlined/>} onClick={() => getReport()}>
                        {isSmall ? '' : languagesCms.REPORT}
                    </Button>
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
          <Link to="/tasks">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={"Ara"} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default TaskComments;
